import React from "react";
import "./assets/css/dropdown.css";
import PostApiCall from "./Api";
import GetApiCall from "./GetApi";
class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      Products: [],
      productId: null,
      Feature: [],
      items: [],
      isLoaded: false,
      featureProducts: [],
      MenuCategory: false,
      HamuburgerIcon: true,
      CrossIcon: false,
    };
  }

  componentDidMount() {

    if (localStorage.getItem('CustomerData') == null) {
      GetApiCall.getRequest("generateToken").then((resultdes) => {
        if (resultdes.status == 200 || resultdes.status == 201) {
          resultdes.json().then((obj) => {
            localStorage.setItem("access", JSON.stringify(obj.token));
            PostApiCall.postRequest(
              {
                catCount: "*",
              },
              "GetProductCategories"
            ).then((results) =>
              // const objs = JSON.parse(result._bodyText)
              results.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                  // console.log(obj1.data);
                  this.setState({
                    Items: obj1.data,
                  });
                }
              })
            );
          })
        }
      }
      );
    } else {
      PostApiCall.postRequest(
        {
          catCount: "*",
        },
        "GetProductCategories"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            // console.log(obj1.data);
            this.setState({
              Items: obj1.data,
            });
          }
        })
      );
    }

  }

  render() {
    return (
      <div
        class="container-fluid py-3 justify-content-between d-flex align-items-center dropdown-tab"
        style={{ borderTop: "1px solid #d7ded9" }}
      >
        <div class="d-flex  image-links" style={{ width: "100%" }}>
          {this.state.Items.filter((data) => data.fld_categoryid == 15)
            .map((product, i) => (
              <a
                class="link-cat nav-link-font"
                href={`${process.env.PUBLIC_URL
                  }/${(product.fld_categoryname.toLowerCase()).replace(/\s/g, '-')}/${product.fld_categoryid}`}
              >
                <p>{product.fld_categoryname}</p>
              </a>
            ))}
          {this.state.Items.filter((data) => data.fld_categoryid !== 15)
            .map((product, i) => (
              <a
                class="link-cat nav-link-font"
                href={`${process.env.PUBLIC_URL
                  }/${(product.fld_categoryname.toLowerCase()).replace(/\s/g, '-')}/${product.fld_categoryid}`}
              >
                <p>{product.fld_categoryname}</p>
              </a>
            ))}
        </div>

        <div >
          <div class="d-flex">
            <i class="fa fa-phone header-contact-icon-font"
              aria-hidden="true"
            ></i>

            <div style={{ marginRight: "4px" }}>
              <a
                href="tel:+9172990 48725"
                className="headerContact header-contact-font"
                
              >
                +91 72990 48725
              </a>
            </div>
          </div>
          <div class="d-flex">
            <i class="fa fa-envelope header-email-icon-font"
              aria-hidden="true"
            ></i>

            <div style={{ marginRight: "20px" }}>
              <a
                href="mailto:wecare@flosilshop.com"
                className="headerContact header-contact-font"
              >
                wecare@flosilshop.com
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DropDown;
