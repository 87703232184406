import React, { Component } from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Notiflix from "notiflix";
import PostApiCall from "../Api";
import "./CustomerCardHistory.css";

class Customerard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      BestProduct: [],
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    this.getCartdetails();
  }

  getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();
          //console.log(res1.data)

          this.setState({ BestProduct: res1.data });
        }
      });
  };

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 4,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    let progessed = 0;

    return (
      <React.Fragment>
        <section class="section-padding mt-3" style={{ background: "#fff" }}>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title mt-2">
                  <h4>In Cart</h4>
                  <div class="title-divider"></div>
                </div>
              </div>
            </div>
            {this.state.BestProduct.length != 0 ? (
              <div class="row">
                <div
                  class="col-md-12"
                  style={{ background: "#fff !important" }}
                >
                  <Carousel
                    className="cardslider"
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container CustomerCardHistory"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-50-px"
                  >
                    {this.state.BestProduct.map((item) => (
                      <div class="item">
                        <div class="product-box product-box-height">
                          <a
                            href={`${process.env.PUBLIC_URL}/productdetail/${item.fld_variantid}}`}
                            class="action quickview"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalCenter "
                          >
                            <i class="pe-7s-search"></i>
                          </a>

                          <div class="p-images">
                            <img
                              src={item.VariantImage}
                              alt={item.fld_variantname}
                            ></img>
                          </div>

                          <div class="content-box">
                            <div>
                              <a
                                href={`${process.env.PUBLIC_URL}/productdetail/${item.fld_variantid}}`}
                              >
                                <h6 class="product-name">
                                  {item.fld_variantname}
                                  <span className="productItemName">
                                    {item.fld_itemname}
                                  </span>
                                </h6>

                                {parseFloat(
                                  item.fld_mrp
                                ).toFixed(2) === "1.00" ? (
                                  <div className="col-md-6 col-12 free-item-customer-history text-success text-end">
                                    Free
                                  </div>
                                ) : (
                                  <>
                                    <h6 class="price">
                                      <i class="fas fa-rupee-sign"></i>{" "}
                                      {parseFloat(
                                        item.fld_website_discount_price
                                      ).toFixed(2)}
                                      <span>
                                        MRP:&nbsp;
                                        <i class="fas fa-rupee-sign"></i>{" "}
                                        <s>
                                          ₹{parseFloat(item.fld_mrp).toFixed(2)}
                                        </s>
                                        <br></br>
                                        <em style={{ color: "black" }}>
                                          You Save{" "}
                                          <i class="fas fa-rupee-sign"></i> ₹
                                          {parseFloat(
                                            item.you_save_amount
                                          ).toFixed(2)}{" "}
                                          (
                                          {parseFloat(
                                            item.you_save_per
                                          ).toFixed(2)}
                                          %)
                                        </em>
                                      </span>
                                    </h6>
                                  </>
                                )}
                                <h6
                                  class="weight"
                                  style={{
                                    color: "Out Of Stock" ? "red" : "green",
                                  }}
                                >
                                  {item.available_stock}{" "}
                                </h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-md-12" style={{ background: "#fff!important" }}>
                  <div class="item" style={{ justifyContent: "center" }}>
                    Your flosil cart is empty!
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Customerard;
