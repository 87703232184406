import React from "react";
import Collapsible from "react-collapsible";
import PostApiCall from "./Api";
import Notiflix from "notiflix";

class Mobilemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      Items: [],
      ProductCart: [],
      LoginData: [],
      MenuCategory: false,
      ProductAmount: 0,
    };
  }

  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#0f3d74",
    // });

    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));

    if (loginDetails != null) {
      this.setState({
        LoginData: loginDetails,
      });
    }

    // Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductCategories"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj1.data)
          this.setState({
            Items: obj1.data,
          });
        }
      })
    );
    this.getCartdetails();
  }

  getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();

          // console.log(res1.data)
          this.setState({
            ProductCart: res1.data,
          });
          if (res1.data.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            this.setState({
              ProductAmount: res1.data[0].fld_cart_amount,
            });
          }
        }
      });
  };

  changeMenu = () => {
    this.setState({ showMenu: true });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    let menu;
    let menuOverlay;

    if (this.state.showMenu === true) {
      menu = (
        <div
          class={`mobile-menu ${this.state.showMenu ? "slide-in" : "slide-out"
            }`}
        >
          {" "}
          <button
            onClick={() => this.setState({ showMenu: false })}
            class="btn download-invoice justify-content-center align-items-center mt-2 mr-2"
            style={{
              float: "right",
              background: "#0f3d74",
              color: "#fff",
            }}
          >
            X
          </button>
          <div className="mobile-container">
            <ul class="menu-options">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <div>
                  <Collapsible trigger="Shop by Category">
                    <ul class="mobile-menu-categories">
                      {this.state.Items.map((product, i) => (
                        <li>
                          <a
                            href={`${process.env.PUBLIC_URL
                              }/${product.fld_categoryname.trim()}/${product.fld_categoryid
                              }`}
                          >
                            {product.fld_categoryname}{" "}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Collapsible>
                </div>
              </li>
              {this.state.LoginData.length == 0 ? (
                <li>
                  <a href="/login">Login</a>
                </li>
              ) : (
                <li>
                  <a href="/customerprofile">My Account</a>
                </li>
              )}

              <li
                style={{
                  display: this.state.LoginData.length == 0 ? "block" : "none",
                }}
              >
                <a href="/register">Register</a>
              </li>

              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li
                style={{
                  display: this.state.LoginData.length == 0 ? "none" : "block",
                }}
              >
                <a
                  onClick={() => {
                    localStorage.removeItem("CustomerData");
                    localStorage.removeItem("access");
                    window.location.href = "/";
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <div class="mobile-contact-options">
              <div className="footerfollowus">
                <p style={{ color: "333", marginRight: "15px" }}>
                  {" "}
                  Got question ? Call us 24/7 on:{" "}
                </p>
                <a
                  href="tel:+9172990 48725"
                  className="headerContact footer-margin"
                  style={{
                    color: "#006fbf",
                    marginRight: "18px",
                    marginTop: "0px",
                    fontWeight: "400",
                    fontSize: "27px",
                  }}
                >
                  +91 72990 48725
                </a>
                <p style={{ color: "#333" }}>Follow us on:</p>

                <div
                  class="social-icons footer-margin"
                  style={{
                    // marginLeft: "15px",
                    marginTop: "1px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/Flosilshop"
                      target="_blank"
                    >
                      <i
                        class="fa fa-facebook-square social-icon social-icon-sidebar ml-0 pl-0"
                        style={{ color: "#1877f2" }}
                      ></i>
                    </a>
                    <a
                      href="https://www.instagram.com/flosilshop/"
                      target="_blank"
                    >
                      <i
                        class="fa fa-instagram social-icon social-icon-sidebar"
                        style={{ color: "#cd486b" }}
                      ></i>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/flosil-shop"
                      target="_blank"
                    >
                      <i
                        class="fa fa-linkedin-square social-icon social-icon-sidebar"
                        style={{ color: "rgb(29, 155, 240)" }}
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    <a href="https://twitter.com/FlosilShop" target="_blank">
                      <i
                        class="fa fa-twitter-square social-icon social-icon-sidebar ml-0 pl-0"
                        style={{ color: "#1877f2" }}
                      ></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCZGcj15-l6q7NfuQRuSuELQ/featured"
                      target="_blank"
                    >
                      <i
                        class="fa fa-youtube-square social-icon social-icon-sidebar"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </a>
                    {/* <a href="https://pin.it/7qCuxAK" target="_blank">
                      <i
                        class="fa fa-pinterest-square social-icon"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </a> */}
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      menuOverlay = <div class="menu-overlay" onClick={this.hideMenu}></div>;
    }
    return (
      <div style={{ display: "inline" }}>
        <div class="mobile-menu-icon menu-bars">
          <i
            class="fa fa-bars "
            aria-hidden="true"
            onClick={this.changeMenu}
          ></i>
        </div>
        {menuOverlay}
        {menu}
      </div>
    );
  }
}

export default Mobilemenu;
