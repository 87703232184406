import React, { Component } from "react";

import Notiflix from "notiflix";
import PostApiCall from "../Api";
import {
  MDBDataTable,
  MDBCol,
  MDBTableBody,
  MDBTableHead,
  CardTitle,
} from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import Searchicon from "./images/search.png";
import ViewInvoiceForm from "../ViewInvoiceForm";
// import InvoiceForm from "../InvoiceForm"

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistoryData: [],
      searchInput: "",
      OpenModalForDownloadInvoice: false,
      idToFetch: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${parseInt(CustomerDetails[0].fld_customerid)} Order By fld_dispatch_id DESC`,
        select: `distinct fld_delivery_challan,fld_order_number,fld_order_date,customer_name,total_items,fld_payment_status,fld_order_status,fld_dispatch_id,fld_order_amount,fld_dispatch_status,Total_Inclusive_Tax,fld_customerid  `

      },
      "GetDispatchDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj);
          this.setState({
            orderHistoryData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("invoice");
    var opt = {
      margin: 1,
      filename: "invoice.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      //   pagebreak: {avoid: ‘div’}
    };
    // ////console.log(opt)
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        Notiflix.Loading.Remove();
      });
  }
  toggleDownloadInvoice = (orderId) => {
    Notiflix.Loading.Circle();
    this.setState(
      {
        idToFetch: orderId,
      },
      () =>
        this.setState(
          {
            OpenModalForDownloadInvoice:
              !this.state.OpenModalForDownloadInvoice,
          },
          //console.log(this.state.idToFetch)
        )
    );
  };

  render() {
    //console.log(this.state.orderHistoryData)
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 75,
        },
        {
          label: "Order Number",
          field: "number",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Date",
          field: "date",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Total items",
          field: "item",
          sort: "disabled",
          width: 300,
        },

        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Status",
          field: "status",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Payment Status",
          field: "paystatus",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Invoice",
          field: "invoice",
          sort: "disabled",
          width: 300,
        },
      ],
      rows: this.state.orderHistoryData
        .filter((data) => {
          if (this.state.searchInput == "") {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_number
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_payment_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
        })
        .map((data, i) => {
          return {
            serial: i + 1,
            number: data.fld_order_number,
            date: data.fld_order_date,

            item: data.total_items,
            ordervalue: data.fld_order_amount,
            status: data.fld_order_status,
            paystatus: data.fld_payment_status,
            invoice: (
              <div>


                {
                  data.fld_order_status == "Delivered" ?
                    <button
                      onClick={() => this.toggleDownloadInvoice(data.fld_dispatch_id)}
                      class="active-button-order"
                    >
                      View Invoice
                    </button>
                    :
                    <button
                      class="disabled-button-order"
                    >
                      View Invoice
                    </button>
                }
              </div>
            ),
          };
        }),
    };

    return (
      <React.Fragment>
        <Card className="mini-stats-wid mt-3">
          <CardBody>
            <CardTitle className=" section-title usertitle">
              {this.state.OpenModalForDownloadInvoice ? (
                <h4>Download Invoice</h4>
              ) : (
                <h4>Order History</h4>
              )}

              <div class="title-divider"></div>
            </CardTitle>

            {this.state.OpenModalForDownloadInvoice ? (
              <ViewInvoiceForm
                toogleBack={() =>
                  this.setState({
                    OpenModalForDownloadInvoice:
                      !this.state.OpenModalForDownloadInvoice,
                  })
                }
                IdToFetch={this.state.idToFetch}
              />
            ) : (
              <Row>
                <MDBCol
                  md="12"
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        <span className="">
                          <img
                            src={Searchicon}
                            alt="block user"
                            className="btnicons"
                          ></img>
                        </span>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type the search phrase you wish to search within the grid"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      onChange={(e) => this.seachBarHandler(e)}
                      value={this.state.searchInput}
                    />
                  </div>
                </MDBCol>

                <Col md="12">
                  {this.state.orderHistoryData.length != 0 ? (
                    <MDBDataTable
                      hover
                      // scrollY
                      striped
                      bordered

                      data={data}

                      entriesOptions={[10, 25, 50, 100]}
                      responsive={true}
                    >
                      {/* <MDBTableHead columns={data.columns} /> */}
                      <MDBTableBody rows={data.rows} />
                    </MDBDataTable>
                  ) : (
                    <p>No Data Found</p>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Chart;
