import React from "react";
import "./Search.css";
import PostApiCall from "./Api";
import Notiflix from "notiflix";
import moment from "moment";
import NewCart from "./assets/img/CartIcon.svg";
import UserImage from "./assets/img/user.svg";
import { store } from "./context/store"; 
import DropDown from "./DropDown";
import NewLogo from "./assets/img/to-update.png";
import GetApiCall from "./GetApi";

class Header extends React.Component {
  listener = null;
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      suggestions: [],
      text: "",
      ProductCart: [],
      WishlistData: [],
      SearchText: "",
      Items: [],
      LoginData: [],
      MenuCategory: false,
      ProductAmount: 0,
      Name: null,
      MyAccount: false,
    };
  }

  componentDidMount() {

    Notiflix.Notify.Init({
      width: '280px',
      position: 'center-bottom',
      distance: '10px',
      opacity: 1,
    });

    window.scrollTo(0, 0);

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    if (logindetails != null) {
      this.setState({
        LoginData: logindetails[0],
        Name: logindetails[0].fld_name,
      });
    }

    if (localStorage.getItem('CustomerData') == null) {
      GetApiCall.getRequest("generateToken").then((resultdes) => {
        if (resultdes.status == 200 || resultdes.status == 201) {
          resultdes.json().then((obj) => {
            localStorage.setItem("access", JSON.stringify(obj.token));
            PostApiCall.postRequest(
              {
                catCount: "*",
              },
              "GetProductCategories"
            ).then((results) =>
              // const objs = JSON.parse(result._bodyText)
              results.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                  // console.log(obj1.data)
                  this.setState({
                    Items: obj1.data,
                  });
                }
              })
            );

            window.addEventListener("scroll", this.handleScroll);

            this.getCartdetails();

            if (this.state.suggestions.length == 0) {
              PostApiCall.postRequest(
                {
                  variantName: "",
                },
                "SearchVariantByProduct"
              ).then((results) =>
                // const objs = JSON.parse(result._bodyText)
                results.json().then((obj1) => {
                  if (results.status == 200 || results.status == 201) {
                    //   console.log(obj1.data);

                    this.setState({
                      suggestions: obj1.data,
                    });
                    // this.renderSuggestions()
                  }
                })
              );
            }
          })
        }
      }
      );
    } else {
      PostApiCall.postRequest(
        {
          catCount: "*",
        },
        "GetProductCategories"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            // console.log(obj1.data)
            this.setState({
              Items: obj1.data,
            });
          }
        })
      );

      window.addEventListener("scroll", this.handleScroll);

      this.getCartdetails();

      if (this.state.suggestions.length == 0) {
        PostApiCall.postRequest(
          {
            variantName: "",
          },
          "SearchVariantByProduct"
        ).then((results) =>
          // const objs = JSON.parse(result._bodyText)
          results.json().then((obj1) => {
            if (results.status == 200 || results.status == 201) {
              //   console.log(obj1.data);

              this.setState({
                suggestions: obj1.data,
              });
              // this.renderSuggestions()
            }
          })
        );
      }
    }

  }

  getCartdetails = () => {
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0]?.order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();
          //console.log(res1.data)

          this.setState({
            ProductCart: res1.data,
          });

          this.context.setcart(res1.data.length);
          if (res1.data.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            this.setState({
              ProductAmount: res1.data[0].fld_cart_amount_no_shipping,
            });
            this.context.setcartamount(res1.data[0].fld_cart_amount_preview);
          }
        }
      });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };
  onTextChange = (e) => {
    this.setState({
      SearchText: e.target.value,
    });
  };
  selectedText(value) {
    this.setState(() => ({
      text: value,
      suggestions: [],
    }));
  }

  render() {
    const { text, suggestions } = this.state;
    //console.log(this.state.suggestions)
    //console.log(suggestions)
    return (
      <div>
        <header>
          <div className={`Nav ${this.state.nav && "Nav__black"}`}>
            <div class="container-fluid nav-container">
              <div class="nav ">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-2 d-flex align-items-center">
                      <div class="logo1 header">
                        <a href="/">
                          {" "}
                          <img
                            src={NewLogo}
                            class="logo"
                            alt="logo"
                            height="230px"
                            width="227px"
                          ></img>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-8 ">
                      <div class="search-wrapper">
                        <div id="search" class="d-flex">
                          <div class="search-input-group  flex-fill">
                            <div class="search-input d-flex ">
                              <div class="dropdown search-category ">
                                {this.state.nav && (
                                  <button
                                    type="button"
                                    class="btn_custom dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    onMouseOver={() => {
                                      this.setState({
                                        MenuCategory: true,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({
                                        SearchText: "",
                                      });
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        MenuCategory: true,
                                      });
                                    }}
                                    style={{
                                      color: "##212529",
                                      fontWeight: "300",
                                    }}
                                  >
                                    Shop by Category{" "}
                                    <i
                                      class="arrow down"
                                      style={{
                                        color: "#999",
                                        fontWeight: "300",
                                      }}
                                    ></i>
                                  </button>
                                )}

                                <div
                                  class="dropdown-menu dropdown-menu-left"
                                  x-placement="bottom-start"
                                  onMouseLeave={() => {
                                    this.setState({
                                      MenuCategory: false,
                                    });
                                  }}
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 26px, 0px)",
                                    display:
                                      this.state.MenuCategory == true
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  {this.state.Items.map((product, i) => (
                                    <a
                                      class="dropdown-item"
                                      data-category_id="0"
                                      href={`${process.env.PUBLIC_URL
                                        }/${(product.fld_categoryname.toLowerCase()).replace(/\s/g, '-')}/$ {product.fld_categoryid
                                        }`}
                                    >
                                      {product.fld_categoryname}
                                    </a>
                                  ))}
                                </div>
                              </div>
                              <div class="flex-fill">
                                <input
                                  type="text"
                                  name="search"
                                  onChange={this.onTextChange}
                                  value={this.state.SearchText}
                                  data-autocomplete="5"
                                  data-autocomplete_route="extension/maza/product/product/autocomplete"
                                  placeholder="Search Products"
                                  autocomplete="off"
                                />
                              </div>
                            </div>
                            <div
                              class="card search-result"
                              style={{
                                display:
                                  this.state.SearchText == ""
                                    ? "none"
                                    : "block",
                              }}
                              onMouseLeave={() => {
                                this.setState({
                                  SearchText: "",
                                });
                              }}
                            >
                              <div class="card-body ">

                                <ul class="list-group list-group-flush">

                                  {
                                    suggestions
                                      .filter((data, index) => {
                                        if (
                                          data.fld_variantname
                                            .toLowerCase()
                                            .includes(
                                              this.state.SearchText.toLowerCase()
                                            ) &&
                                          this.state.SearchText !== ""
                                        ) {
                                          return data;
                                        }
                                      })
                                      .map((data, index) => {
                                        // console.log(data);
                                        return (
                                          <li class="list-group-item">
                                            <a
                                              href={`${process.env.PUBLIC_URL
                                                }/productdetail/${(data.fld_variantname.toLowerCase()).replace(
                                                  /[^a-zA-Z0-9]/g,
                                                  "-"
                                                )}/${data.fld_variantid}`}
                                            >
                                              <img
                                                src={data.fld_imageurl}
                                                alt={data.fld_variantname.replace(
                                                  /\s/g,
                                                  "-"
                                                )}
                                                width="50"
                                                height="50"
                                              />
                                            </a>
                                            <a
                                              href={`${process.env.PUBLIC_URL
                                                }/productdetail/${(data.fld_variantname.toLowerCase()).replace(
                                                  /[^a-zA-Z0-9]/g,
                                                  "-"
                                                )}/${data.fld_variantid}`}
                                              className="searchProduct"
                                            >
                                              <h5>
                                                <span className="Search_variantName">
                                                  {data.fld_variantname}{" "}
                                                </span>
                                                <br />
                                                <span
                                                  style={{ color: "#00000075" }}
                                                >
                                                  {data.fld_categoryname}
                                                </span>
                                              </h5>
                                            </a>
                                            <p className="searchPriceinfo">
                                              ₹{" "}
                                              {parseFloat(
                                                data.fld_selling_price
                                              ).toFixed(2)}{" "}
                                              &nbsp;{" "}
                                            </p>

                                            {data.fld_stock != "Out of Stock" ? (
                                              <a
                                                className="thm-btn search-btn"
                                                onClick={() => {
                                                  Notiflix.Loading.Circle();
                                                  var login =
                                                    localStorage.getItem(
                                                      "CustomerData"
                                                    );
                                                  var logindetails =
                                                    JSON.parse(login);

                                                  var Order =
                                                    localStorage.getItem(
                                                      "OrderData"
                                                    );
                                                  var Orderdetails =
                                                    JSON.parse(Order);

                                                  PostApiCall.postRequest(
                                                    {
                                                      orderdate:
                                                        moment().format("lll"),
                                                      itemdetails: `
                                              [
                                              { 
                                                  "Item":${data.fld_variantid}, 
                                                  "Quantity":${1}
                                              }
                                              ]
                                              `,
                                                      status: "INCART",
                                                      customerid:
                                                        localStorage.getItem(
                                                          "CustomerData"
                                                        ) == null
                                                          ? null
                                                          : logindetails[0]
                                                            .fld_customerid,
                                                      createdon:
                                                        moment().format("lll"),
                                                      updatedon:
                                                        moment().format("lll"),
                                                      orderid:
                                                        localStorage.getItem(
                                                          "OrderData"
                                                        ) == null
                                                          ? 0
                                                          : Orderdetails[0]
                                                            .order_id,

                                                      updatedby:
                                                        localStorage.getItem(
                                                          "CustomerData"
                                                        ) == null
                                                          ? null
                                                          : logindetails[0]
                                                            .fld_userid,
                                                    },
                                                    "AddToCartWebsite"
                                                  ).then((result) =>
                                                    result.json().then((obj) => {
                                                      if (
                                                        result.status == 200 ||
                                                        result.status == 201
                                                      ) {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Success(
                                                          "Product added to Wishlist."
                                                        );
                                                        if (
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          ) == null
                                                        ) {
                                                          localStorage.setItem(
                                                            "OrderData",
                                                            JSON.stringify(
                                                              obj.data
                                                            )
                                                          );
                                                          this.getCartdetails();
                                                        }
                                                        this.getCartdetails();
                                                      } else {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Failure(
                                                          obj.data
                                                        );
                                                      }
                                                    })
                                                  );
                                                }}
                                              >
                                                Add to Cart
                                              </a>
                                            ) : (
                                              <a
                                                class="thm-btn search-btn"
                                                style={{ background: "#a8aba8" }}
                                              >
                                                Sold Out
                                              </a>
                                            )}
                                          </li>
                                        );
                                      })
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              style={{
                                border: "0px",
                                outline: "none",
                                backgroundColor: "white",
                                fontSize: "28px",
                                color: "gray",
                                marginRight: "10px",
                              }}
                            >
                              <i class="fa fa-search icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div class="dropdown useraccountlist">
                        <div class="my-account ">
                          <ul class="account-options">
                            <li>
                              <a href="/cart" className="shoppingBag">
                                <span class="wishlist-number">
                                  {this.context.cart}
                                </span>
                                <img
                                  src={NewCart}
                                  // height="30px"
                                  // width="30px"
                                  alt="cart"
                                  style={{
                                    width: "46px",
                                    // float: "right",
                                    // marginLeft: "9px",
                                    cursor: "pointer",
                                    // marginTop: "7px"
                                  }}
                                />
                              </a>
                              <li>
                                <a href="/cart" className="Cartpricetext">
                                  <span className="shopcart item-price">
                                    ₹{" "}
                                    {parseFloat(
                                      this.context.cartamount
                                    ).toFixed(2)}
                                  </span>
                                </a>
                              </li>
                            </li>
                          </ul>
                        </div>

                        <a
                          href={
                            this.state.LoginData.length == 0
                              ? "/login"
                              : "/customerprofile"
                          }
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            color: "#5c5c5c",
                            fontSize: "20px",
                            position: "absolute",
                            top: "15px",
                            right: "20px",
                          }}
                          onMouseOver={() => {
                            this.setState({ MyAccount: true });
                          }}
                        >
                          <img src={UserImage} height="30px" width="30px"></img>
                        </a>

                        {/* <a
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            color: "#5c5c5c", fontSize: '20px', position: 'absolute',
                            top: '16px'
                          }}
                        >
                          My Account
                        </a> */}
                        {this.state.LoginData.length == 0 ? (
                          <div
                            class="dropdown-menu dropdown-menu-left"
                            x-placement="bottom-start"
                            onMouseLeave={() => {
                              this.setState({
                                MyAccount: false,
                              });
                            }}
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "16px",
                              left: "45px",
                              transform: "translate3d(0px, 26px, 0px)",
                              display: "block",
                              width: "70%",
                              padding: "20px",
                              display:
                                this.state.MyAccount == true ? "block" : "none",
                            }}
                          >
                            <a
                              class="dropdown-item login"
                              data-category_id="0"
                              href="/login"
                            >
                              Login
                            </a>
                            <a
                              class="dropdown-item register"
                              data-category_id="0"
                              href="/register"
                            >
                              Sign up
                            </a>
                          </div>
                        ) : (
                          <div
                            class="dropdown-menu dropdown-menu-left"
                            x-placement="bottom-start"
                            onMouseLeave={() => {
                              this.setState({
                                MyAccount: false,
                              });
                            }}
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "16px",
                              left: "45px",
                              transform: "translate3d(0px, 26px, 0px)",
                              display: "block",
                              width: "70%",
                              padding: "20px",
                              display:
                                this.state.MyAccount == true ? "block" : "none",
                            }}
                          >
                            <a
                              class="dropdown-item login"
                              data-category_id="0"
                              href="/customerprofile"
                            >
                              Hi, {this.state.Name.substr(0, 7) + "..."}
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item register"
                              data-category_id="0"
                              onClick={() => {
                                localStorage.removeItem("CustomerData");
                                localStorage.removeItem("access");
                                window.location.href = "/";
                              }}
                            >
                              Logout
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <DropDown />
      </div>
    );
  }
}
export default Header;
