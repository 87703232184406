import React, { Component } from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Notiflix from "notiflix";
import GetApiCall from "../GetApi";
import BestSelling from "../BestSelling";


class Frequently extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      featureProducts: []
    };

  }

  componentDidMount() {


    GetApiCall.getRequest("GetFeaturedProduct").then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data)
        this.setState({
          featureProducts: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );


  }


  render() {
    const productslider = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 1,
            infinite: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]

    };

    return (
      <React.Fragment>
        <Card className="mt-3">
          <CardBody>
            <CardTitle className="section-title mb-4">
              <h4>Frequently Bought</h4>
              <div class="title-divider"></div>
            </CardTitle>




            <div class="row" style={{ marginRight: '-4px' }}>
              <div class="col-md-12">
                <Slider {...productslider}>
                  {this.state.featureProducts.map(item =>
                    <BestSelling itemdata={item} />
                  )}


                </Slider>
              </div>
            </div>








          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Frequently;
