import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({
    children
}) => {
    const [cart, setcart] = useState(0);
    const [cartamount, setcartamount] = useState(0);
    const [currentPage, setCurrentPage] = useState("Home")
    return (
        <store.Provider value={{ cart, setcart, cartamount, setcartamount, currentPage, setCurrentPage }}>
            {children}
        </store.Provider>
    )
}

export default Provider;