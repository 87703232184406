import React from "react";
import PostApiCall from "./Api";
import Notiflix from "notiflix";
import moment from "moment";

class Searchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      suggestions: [],
      text: "",
      SearchText: "",
    };
  }

  componentDidMount() {
    if (this.state.suggestions.length == 0) {
      PostApiCall.postRequest(
        {
          variantName: "",
        },
        "SearchVariantByProduct"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              suggestions: obj1.data,
            });
            // this.renderSuggestions()
          }
        })
      );
    }
  }

  changeMenu = () => {
    this.setState({ showMenu: true });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  onTextChange = (e) => {
    this.setState({
      SearchText: e.target.value,
    });
  };
  selectedText(value) {
    this.setState(() => ({
      text: value,
      suggestions: [],
    }));
  }

  render() {
    const { text, suggestions } = this.state;

    let menu;
    let menuOverlay;

    if (this.state.showMenu === true) {
      menu = (
        <div
          class={`mobile-menu ${this.state.showMenu ? "slide-in" : "slide-out"
            }`}
        >
          <div className="mobile-container">
            <div class="search-bar">
              <input
                type="text"
                onChange={this.onTextChange}
                value={this.state.SearchText}
                placeholder="Search here"
              ></input>

              <div
                class="card search-result"
                style={{
                  display: this.state.SearchText == "" ? "none" : "block",
                }}
                onMouseLeave={() => {
                  this.setState({
                    SearchText: "",
                  });
                }}
              >
                <div
                  class="card-body "
                  style={{
                    display: this.state.SearchText == "" ? "none" : "block",
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      SearchText: "",
                    });
                  }}
                >
                  <ul class="list-group list-group-flush">
                    {suggestions
                      .filter((data, index) => {
                        if (
                          data.fld_variantname
                            .toLowerCase()
                            .includes(this.state.SearchText.toLowerCase()) &&
                          this.state.SearchText !== ""
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => {
                        return (
                          <li class="list-group-item">
                            <a
                              href={`${process.env.PUBLIC_URL}/productdetail/${data.fld_variantid}/${data.fld_productid}`}
                              className="searchProduct"
                            >
                              <h5>
                                <span className="Search_variantName">
                                  {data.fld_variantname}{" "}
                                </span>
                              </h5>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div class="mobile-contact-options mb-end">
              <div className="footerfollowus">
                <p style={{ color: "333", marginRight: "15px" }}>
                  {" "}
                  Got question ? Call us 24/7 on:{" "}
                </p>
                <a
                  href="tel:+9172990 48725"
                  className="headerContact footer-margin"
                  style={{
                    color: "#006fbf",
                    marginRight: "18px",
                    marginTop: "0px",
                  }}
                >
                  +91 72990 48725
                </a>

                <img
                  src="/assets/img/payment.png"
                  className="footer-margin"
                  style={{ marginRight: "20px" }}
                ></img>
                <p style={{ color: "#333" }}>Follow us on:</p>

                <ul
                  class="social-icons footer-margin"
                  style={{ marginLeft: "15px", marginTop: "1px" }}
                >
                  <li>
                    <a
                      href="https://www.facebook.com/flosilbetcoatings"
                      target="_blank"
                    >
                      <i
                        class="fa fa-facebook-square social-icon"
                        style={{ color: "#1877f2" }}
                      ></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/flosilbet/"
                      target="_blank"
                    >
                      <i
                        class="fa fa-linkedin-square social-icon"
                        aria-hidden="true"
                        style={{ color: "#0a66c2" }}
                      ></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://twitter.com/FBCIPL/" target="_blank">
                      <i
                        class="fa fa-twitter-square social-icon"
                        style={{ color: "rgb(29, 155, 240)" }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/FBCIPL/" target="_blank">
                      <i
                        class="fa fa-instagram social-icon"
                        style={{ color: "#cd486b" }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );

      menuOverlay = <div class="menu-overlay" onClick={this.hideMenu}></div>;
    }
    return (
      <div style={{ display: "inline" }}>
        <div class="mobile-menu-icon">
          <i
            class="fa fa-search"
            aria-hidden="true"
            onClick={this.changeMenu}
          ></i>
        </div>
        {menuOverlay}
        {menu}
      </div>
    );
  }
}

export default Searchbar;
