import React from "react";
import react, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "./Api";
import moment from "moment";
import { store } from "./context/store";
// import GetApiCall from "./GetApi";
// import Slider from "react-slick";

class BestSelling extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      VariantImage: null,
      VariantId: null,
      ProductId: null,
      VariantName: null,
      ItemName: null,
      VariantList: null,
      MRP: null,
      DiscountPrice: null,
      YouSave: null,
      SavePer: null,
      Stock: null,
      Weight: null,
      CategoryName: null,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    const store = this.context;

    try {
      this.setState({
        ItemName: this.props.itemdata.fld_variantname,
        VariantList: this.props.itemdata.variant_list,
        MRP: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[2],
        DiscountPrice: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[3],
        SavePer: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[4],
        YouSave: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[5],
        Stock: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[6],
        VariantId: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[1],
        VariantName: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[0],
        Weight: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[8],
        CategoryName: this.props.itemdata.fld_categoryname,
        VariantImage: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0],
        ProductId: this.props.itemdata.fld_productid,
      });
    } catch {
      try {
        this.setState({
          ItemName: this.props.itemdata.fld_variantname,
          VariantList: this.props.itemdata.variant_list,
          MRP: this.props.itemdata.variant_list.split("$")[1].split(",")[2],
          DiscountPrice: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[3],
          SavePer: this.props.itemdata.variant_list.split("$")[1].split(",")[4],
          YouSave: this.props.itemdata.variant_list.split("$")[1].split(",")[5],
          Stock: this.props.itemdata.variant_list.split("$")[1].split(",")[6],
          VariantId: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[1],
          VariantName: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[0],
          Weight: this.props.itemdata.variant_list.split("$")[1].split(",")[8],
          CategoryName: this.props.itemdata.fld_categoryname,
          VariantImage: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[7]
            .split("~")[0],
          ProductId: this.props.itemdata.fld_productid,
        });
      } catch { }
    }
  }

  getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();

          localStorage.setItem("CardCount", JSON.stringify(res1.data.length));

          this.setState({ ProductCart: res1.data });
          this.context.setcart(res1.data.length);
          this.context.setcartamount(res1.data[0].fld_cart_amount_preview);
        }
      });
  };

  render() {
    const productslider = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    //console.log(this.state.VariantName)

    return (
      <React.Fragment>
        <div class="item">
          <div class="product-box product-box-height product-box-outer"> 
            <div class="p-images best-seller-image-size blog-images">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.state.VariantId}`}
              >
                <img className="w-100" src={this.state.VariantImage} alt={
                  this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )
                }></img>
              </a>
            </div>

            <div class="content-box">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.state.VariantId}`}
              >
                <span style={{ color: "#00000075" }}>
                  {this.state.CategoryName}
                </span>

                <p className="product-title2">
                  {this.state.VariantName?.split("(")[0]}
                  <br />({this.state.VariantName?.split("(")[1]})
                </p>
              </a>
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.state.VariantId}`}
              >
                <h6 class="price">
                  <i class="fas fa-rupee-sign"></i> ₹{" "}
                  {parseFloat(this.state.DiscountPrice).toFixed(2)}
                  <span style={{ display: this.state.DiscountPrice == this.state.MRP ? "none" : "flex" }}>
                    <i class="fas fa-rupee-sign"></i>{" "}
                    <s> ₹ {parseFloat(this.state.MRP).toFixed(2)}</s>
                    <br></br>
                    <em style={{ color: "#3e3e3e" }}>
                      You Save: ₹ {parseFloat(this.state.YouSave).toFixed(2)} (
                      {parseFloat(this.state.SavePer).toFixed(2)}%)
                    </em>
                  </span>
                  {/* <h6 class="weight" style={{ color: this.state.Stock == "Out of Stock" ? 'red' : 'green' }}>{this.state.Stock} </h6> */}
                </h6>
              </a>
            </div>
            <div class="btns">
              {this.state.Stock == "Out of Stock" ? (
                <a
                  class="thm-btn itm-btns"
                  style={{
                    background: "#a8aba8",
                    cursor: "default",
                    textAlign: "center",
                  }}
                >
                  Sold Out
                </a>
              ) : (
                <a
                  class="thm-btn itm-btns"
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => {
                    // console.log('Cart')
                    Notiflix.Loading.Circle();
                    var login = localStorage.getItem("CustomerData");
                    var logindetails = JSON.parse(login);

                    var Order = localStorage.getItem("OrderData");
                    var Orderdetails = JSON.parse(Order);

                    // ===========with Login=========
                    PostApiCall.postRequest(
                      {
                        orderdate: moment().format("lll"),
                        itemdetails: `
                                          [
                                          { 
                                              "Item":${this.state.VariantId},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                        status: "INCART",
                        customerid:
                          localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                        createdon: moment().format("lll"),
                        updatedon: moment().format("lll"),
                        orderid:
                          localStorage.getItem("OrderData") == null
                            ? 0
                            : Orderdetails[0].order_id,
                        updatedby:
                          localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_userid,
                      },
                      "AddToCartWebsite"
                    ).then((result) =>
                      result.json().then((obj) => {
                        if (result.status == 200 || result.status == 201) {
                          Notiflix.Loading.Remove();
                          Notiflix.Notify.Success("Product added to Cart.");
                          // console.log(obj.data)
                          if (localStorage.getItem("OrderData") == null) {
                            //console.log('datastore')
                            localStorage.setItem(
                              "OrderData",
                              JSON.stringify(obj.data)
                            );
                            this.getCartdetails();
                          }
                          this.getCartdetails();
                        } else {
                          Notiflix.Loading.Remove();
                          Notiflix.Notify.failure(obj.data);
                        }
                      })
                    );
                  }}
                >
                  <i class="bi bi-basket"></i> &nbsp; Add to Cart
                </a>
              )}
            </div>
          </div>
        </div>

        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default BestSelling;
