import React from "react";
import Mobilemenu from "./Mobilemenu";
import Searchbar from "./Searchbar";
import Notiflix from "notiflix";
import PostApiCall from "./Api"; 
import { store } from "./context/store";
import NewLogo from "./assets/img/to-update.png";
import GetApiCall from "./GetApi";

class Headermobile extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      ProductCart: [],
    };
  }

  componentDidMount() {

    Notiflix.Notify.Init({
      width: '280px',
      position: 'center-bottom',
      distance: '10px',
      opacity: 1,
    });

    if (localStorage.getItem('CustomerData') == null) {
      GetApiCall.getRequest("generateToken").then((resultdes) => {
        if (resultdes.status == 200 || resultdes.status == 201) {
          resultdes.json().then((obj) => {
            localStorage.setItem("access", JSON.stringify(obj.token));
            this.getCartdetails();
          })
        }
      }
      );
    } else {
      this.getCartdetails();
    }

  }




  getCartdetails = () => {
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();
          // console.log(res1.data)

          this.setState({
            ProductCart: res1.data,
          });
          this.context.setcart(res1.data.length);
        }
      });
  };

  render() {
    return (
      <div>
        {/* <BrowserView>
          <Header />
        </BrowserView>
        <MobileView> */}
        <div class="header-mobile">
          <div class="container-fluid">
            <div class="row d-flex align-items-center">
              <div class="col-md-4 col-2">
                <div>
                  <Mobilemenu></Mobilemenu>
                </div>
              </div>
              <div class="col-md-4 col-7 ">
                <div class="logo1">
                  <a href="/">
                    {" "}
                    <img
                      src={NewLogo}
                      class="logo img-fluid img-responsive"
                      alt="logo"
                    ></img>
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-3">
                <ul class="account-options">
                  <li>
                    <Searchbar></Searchbar>
                  </li>
                  <li>
                    <a href="/cart">
                      <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                      <span class="wishlist-number">{this.context.cart}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* </MobileView> */}
      </div>
    );
  }
}

export default Headermobile;
