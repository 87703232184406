import { Component } from "react";
import VerifiedIcon from "../assets/img/VerifiedIcon.svg"
import { FaStar } from "react-icons/fa"
import Notiflix from "notiflix";
import PostApiCall from "../Api";
import moment from "moment";



class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rating: null,
            hover: null,
            Headline: null,
            ReviewDescription: null,

        }
    }



    onPostReview() {
        if (this.state.rating != null) {
            if (this.state.Headline != null) {
                if (this.state.ReviewDescription != null) {

                    Notiflix.Loading.Circle()
                    let loginDetails = localStorage.getItem("CustomerData")
                    let customerDetails = JSON.parse(loginDetails)
                    PostApiCall.postRequest({
                        userId: customerDetails[0].fld_customerid,
                        variantId: this.props.variantId,
                        reviewid: null,
                        rating: this.state.rating,
                        headline: this.state.Headline,
                        reviewDescription: this.state.ReviewDescription,
                        // reviewDate: moment().format("DD-MM-YYYY"),
                    }, "AddProductReview").then((result) =>
                        result.json().then((obj) => {

                            if (result.status == 200 || result.status == 201) {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Success("Your review has been submitted successfully");
                                window.location.reload()
                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        }))
                } else {
                    Notiflix.Notify.Failure("Please enter Description for your review")
                }

            } else {
                Notiflix.Notify.Failure("Please enter Headline for your review")
            }

        } else {
            Notiflix.Notify.Failure("Please give a rating to this product")
        }
    }


    render() {
        return (
            <>
                <div className="box">
                    <div className="box-container">
                        <div className="create-review">
                            <h4>Create Review</h4>
                        </div>
                        <div className="productForReview">
                            <div className="product-reviewImage">
                                <img src={this.props.variantImage} height="30%" width="30%"></img>
                            </div>
                            <div className="variantNameReview">
                                {this.props.variantName}
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div className="over-all-rating">
                                <div >
                                    <h4>Overall rating</h4>
                                </div>
                                <div
                                    style={{ display: this.state.rating != null ? "block" : "none" }}
                                    className="container-clear" onClick={() => {
                                        this.setState({
                                            rating: null
                                        })
                                    }}>clear</div>
                            </div>
                            <div>
                                {[...Array(5)].map((star, i) => {

                                    const ratingValue = i + 1;

                                    return (
                                        <label className="star-review">
                                            <input type="radio" value={this.state.rating} onClick={() => {
                                                this.setState({
                                                    rating: ratingValue,
                                                })
                                            }} />
                                            <FaStar size={35} className="star" color={ratingValue <= (this.state.hover || this.state.rating) ? "#FFC107" : "#e4e5e9"}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        hover: ratingValue
                                                    })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        hover: null
                                                    })
                                                }}
                                            />
                                        </label>

                                    )
                                })}

                            </div>
                        </div>
                        <hr />

                        <div>
                            <div className="over-all-rating">
                                <div >
                                    <h4>Add Headline</h4>
                                </div>

                            </div>
                            <div className="headline">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formGroupExampleInput"
                                        placeholder="What's the most important to know?"
                                        value={this.state.Headline}
                                        onChange={(headline) => {
                                            this.setState({
                                                Headline: headline.target.value
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                        <hr />

                        <div>
                            <div className="over-all-rating">
                                <div >
                                    <h4>Add a written review</h4>
                                </div>

                            </div>
                            <div className="headline">
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="5"
                                        placeholder="What did you like or dislike? What did you use this product for?"
                                        value={this.state.ReviewDescription}
                                        onChange={(reviewDescription) => {
                                            this.setState({
                                                ReviewDescription: reviewDescription.target.value
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="submit-review">
                            <button className="btn align-items-center submit-review-button mt-3" onClick={this.onPostReview.bind(this)}>
                                Submit
                            </button>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default Container