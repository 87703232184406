import React from "react";
import Menu from "./Header";
import Footer from "./Footer";
import PostApiCall from "./Api";
import { ToWords } from "to-words";
import ManageCustomerAPI from "./ManageCustomerAPI";
import Notiflix from "notiflix";
import NewLogo from "./assets/img/to-update.png";

// const payu = require('payu-sdk')({
//   key: this.state.MerKey,
//   salt: this.state.MerSalt, // should be on server side only
// })

class ViewInvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingAddress: [],
      BillingAddress: [],
      CartData: [],
      SummaryData: [],
      OfferData: [],
      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

      CustomerLoginData: [],

      BankOffer: [],
      MainOrder: [],
      TxnId: null,

      MerKey: "2B5vIP",
      MerSalt: "TXx4C0xYqrdqSazoBUmbuLV25F1mfQWk",
      CustomerHash: "",
      AmountInWords: null,
      MainOrders: [],
      Payment_Mode: null,
      clientPhoneNumber: null,
      clientEmail: null,
      clientAddress: null,
      clientGST: null,
      clientWesite: null,
      PaymentBy: null,
    };
  }

  componentDidMount() {
    ManageCustomerAPI.postRequest(
      {
        whereClause: `where fld_company_id = ${1}`,
      },
      "API/GetClientData"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          // console.log(obj.data);
          this.setState({
            clientLogo: obj.data[0].fld_storelogo,
            clientName: obj.data[0].fld_companyname,
            clientPhoneNumber: obj.data[0].fld_mobilenumber,
            clientEmail: obj.data[0].fld_email,
            clientAddress: obj.data[0].fld_address,
            clientGST: obj.data[0].fld_gst,
            clientWesite: obj.data[0].fld_website
          });

          //console.log(obj)
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where fld_dispatch_id=${parseInt(this.props.IdToFetch)}`,
        select: "*"
      },
      "GetDispatchDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          this.setState(
            {
              MainOrder: obj.data,
              MainOrders: obj.data[0],
              Payment_Mode: obj.data[0].Payment_Mode,
              PaymentBy: obj.data[0].customer_name
            },
            () => Notiflix.Loading.Remove()
          );
          this.state.MainOrder.map((data, i) => {
            if (i === 0) {
              const toWords = new ToWords();
              let words = toWords.convert(data.Total_Inclusive_Tax);
              this.setState({
                AmountInWords: words,
              });
            }
          });
        }
      })
    );
  }

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("invoiceForm");
    var opt = {
      margin: 1,
      filename: "Invoice.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      //   pagebreak: {avoid: ‘div’}
    };
    // //console.log(opt)
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => { });
  }

  render() {

    return (
      <div>
        <div class="container checkout-section invoice">
          <div class="container-box">
            <div class="table-responsive">
              <table
                style={{
                  width: "1000px",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  bottom: "0px",
                  borderRightColor: "#000",
                  marginTop: "30px",
                  borderCollapse: "collapse",
                }}
                border="1"
                cellspacing="0"
                cellpadding="0"
              >
                <tbody>
                  <tr>
                    <td
                      colspan="10"
                      style={{ width: "20%", verticalAlign: "middle" }}
                    >
                      <button
                        onClick={this.props.toogleBack}
                        class="btn download-invoice justify-content-center align-items-center "
                        style={{
                          float: "right",
                          background: "#0f3d74",
                          color: "#fff",
                        }}
                      >
                        Back
                      </button>
                      <button
                        onClick={this.DownloadInvoicePdf}
                        class="btn download-invoice justify-content-center align-items-center mr-2"
                        style={{
                          float: "right",
                          background: "#0f3d74",
                          color: "#fff",
                        }}
                      >
                        Download Invoice
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div id="invoiceForm">
                <table
                  style={{
                    width: "1000px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    bottom: "0px",
                    borderRightColor: "#000",
                    marginTop: "30px",
                    borderCollapse: "collapse",
                  }}
                  border="1"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>

                    <tr>
                      <td
                        colspan="2"
                        style={{ width: "20%", verticalAlign: "middle" }}
                      >
                        <img
                          src={NewLogo}
                          style={{
                            width: "100%",
                            marginRight: "auto",
                            marginLeft: "auto",
                            verticalAlign: "middle",
                          }}
                          alt="logo"
                        />{" "}
                      </td>
                      <td colspan="8" style={{ width: "80%" }}>
                        {" "}
                        <h2
                          style={{
                            textAlign: "center",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.clientName}
                        </h2>
                        <p style={{ textAlign: "center" }}>
                          {this.state.clientAddress}
                          <br />
                          Phone:{" "}
                          <a href={`tel:${this.state.clientPhoneNumber}`}>{this.state.clientPhoneNumber}</a>
                        </p>
                        <p>GSTIN – {this.state.clientGST}</p>
                        <tr
                          rowspan="8"
                          class="success"
                          style={{
                            display: "table",
                            width: "100%",
                            backgroundColor: "#f7f7f7",
                          }}
                        >
                          <td
                            colspan="8"
                            style={{
                              textAlign: "right",
                              paddingRight: "1%",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Retail Tax Invoice
                          </td>
                        </tr>
                      </td>
                    </tr>

                    {this.state.MainOrder.map((data, i) => {
                      if (i === 0) {
                        return (
                          <tr>
                            <td
                              colspan="1"
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Order Date
                              </span>
                            </td>

                            <td
                              colspan="3"
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              {data.fld_order_date}
                            </td>

                            <td
                              colspan="3"
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Customer Order No.
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              {data.fld_order_number}
                            </td>
                          </tr>
                        );
                      }
                    })}

                    {this.state.MainOrder.map((data, i) => {
                      if (i === 0) {
                        return (

                          <tr>
                            <td colspan="1" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Invoice Number</span></td>

                            <td colspan="9" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                              {data.fld_invoice_number ? data.fld_invoice_number : "0"}</td>
                          </tr>


                        )
                      }
                    })}

                    <tr class="success">
                      <td
                        colspan="4"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          fontWeight: "bold",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Billing Address
                      </td>
                      <td
                        colspan="4"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          fontWeight: "bold",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Shipping Address
                      </td>
                    </tr>
                    {this.state.MainOrder.map((data, i) => {
                      if (i === 0) {
                        return (
                          <tr>
                            <td
                              colspan="4"
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                {data.fld_addressType}
                              </span>
                              <p>
                                {data.fld_address}
                                <br />
                                {data.fld_city} -{data.fld_Pincode}
                                <br />
                                {data.fld_state} , {data.fld_country}
                                <br />
                                <b> Phone:</b>(+91) {data.fld_mobile}
                                <br />
                                <b>Landmark:</b> {data.fld_landmark}
                                <br />
                                <b> GSTIN:</b> {data.fld_GSTIN} <br />
                                <b>Contact Person:</b> {data.fld_contactperson}{" "}
                                (+91) {data.fld_billingmobile}
                              </p>
                            </td>

                            <td
                              colspan="4"
                              style={{
                                textAlign: "left",
                                paddingLeft: "1%",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                {data.fld_shippingaddressType}
                              </span>
                              <p>
                                {data.fld_shippingaddress}
                                <br />
                                {data.fld_shippingcity} -
                                {data.fld_shippingPincode}.<br />
                                {data.fld_shippingstate} ,{" "}
                                {data.fld_shippingcountry} .
                                <br />
                                <b> Phone:</b>(+91) {data.fld_shippingmobile}
                                <br />
                                <b>Landmark:</b> {data.fld_shippinglandmark}
                                <br />
                                <b> GSTIN:</b> {data.fld_shippingGSTIN} <br />
                                <b>Contact Person:</b>{" "}
                                {data.fld_shippingcontactperson} (+91){" "}
                                {data.fld_shippingmobile}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                <table
                  style={{
                    width: "1000px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRightColor: "#000",
                    borderTop: "hidden",
                  }}
                  border="1"
                  cellspacing="0"
                  cellPadding="0"
                >
                  <tbody>
                    <tr class="success">
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>S.No.</span>
                      </td>

                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>HSN Code</span>
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Product</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Quantity</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Price Per Unit</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Gross Price (A)</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Discount(B)
                        </span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>GST Rate(%)</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>GST Amount(C)</span>
                      </td>
                      <td
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Amount (INR)<br />
                          (A-B+C)
                        </span>
                      </td>
                      {/* <!--   <td style="padding-top: 1%; padding-bottom: 1%;text-align:center"><span style={{fontWeight:'bold'}}></span></td> -->
                       */}
                    </tr>
                    {this.state.MainOrder.map((data, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{data.fld_hsncode}</td>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          {data.variant_name} <br />
                          <span>
                            <strong>{data.item_name}</strong>
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {data.fld_quantity}
                        </td>
                        <td style={{ textAlign: "right", paddingRight: "1%" }}>
                          {parseFloat(data.fld_mrp).toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right", paddingRight: "1%" }}>
                          {parseFloat(data.fld_item_value).toFixed(2)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {parseFloat(data.fld_discount_amount).toFixed(2)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {data.fld_GSTRate}%
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {parseFloat(data.fld_GSTamount).toFixed(2)}
                        </td>

                        <td style={{ textAlign: "right", paddingRight: "1%" }}>
                          {parseFloat(
                            data.fld_netvalue * data.fld_quantity
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table
                  style={{
                    width: "1000px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRightColor: "#000",
                    borderTop: "hidden",
                  }}
                  border="1"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr style={{ width: "100%" }}>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "1%",
                          width: "65%",
                        }}
                      >
                        <table
                          style={{
                            width: "620px",
                            borderRightColor: "#000",
                            marginRight: " -60px",
                            borderCollapse: "collapse",
                          }}
                          border="1"
                        >
                          {this.state.MainOrder.map((data, i) => {
                            if (i === 0) {
                              return (
                                <tbody>
                                  <tr>
                                    <th
                                      colspan="10"
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "1%",
                                        paddingBottom: "1%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Total Products to be Delivered :{" "}
                                        {this.state.MainOrder.length} Products (
                                        {data.Total_Item_Quantity} Items)
                                      </span>
                                    </th>
                                  </tr>

                                  <tr class="success">
                                    <th style={{ textAlign: "center" }}>
                                      GST Rate(%)
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Item
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Amount
                                    </th>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>0%</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_In_GstRate_0}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_Amnt_In_GstRate_0 == null
                                        ? "NA"
                                        : data.Item_Amnt_In_GstRate_0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>5%</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_In_GstRate_5}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_Amnt_In_GstRate_5 == null
                                        ? "NA"
                                        : data.Item_Amnt_In_GstRate_5}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ textAlign: "center" }}>12%</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_In_GstRate_12}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_Amnt_In_GstRate_12 == null
                                        ? "NA"
                                        : data.Item_Amnt_In_GstRate_12}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ textAlign: "center" }}>18%</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_In_GstRate_18}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_Amnt_In_GstRate_18 == null
                                        ? "NA"
                                        : data.Item_Amnt_In_GstRate_18}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ textAlign: "center" }}>28%</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_In_GstRate_28}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      {data.Item_Amnt_In_GstRate_28 == null
                                        ? "NA"
                                        : data.Item_Amnt_In_GstRate_28}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }
                          })}
                        </table>
                      </td>
                      {this.state.MainOrder.map((data, i) => {
                        if (i === 0) {
                          return (
                            <td>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Value Before Discount</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.fld_sub_total_mrp).toFixed(2)}
                                </td>

                              </tr>

                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Discount ({data.fld_coupon_code})</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.fld_coupon_discount == null ? 0 : data.fld_coupon_discount).toFixed(2)}
                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Taxable Value</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.fld_sub_total_mrp - data.fld_coupon_discount).toFixed(2)}
                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  GST</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {data.Item_Amnt_In_GstRate_5 != null ? data.Item_Amnt_In_GstRate_5 : data.Item_Amnt_In_GstRate_12 != null ? data.Item_Amnt_In_GstRate_12 : data.Item_Amnt_In_GstRate_18 != null ? data.Item_Amnt_In_GstRate_18 : data.Item_Amnt_In_GstRate_28 != null ? data.Item_Amnt_In_GstRate_28 : "0.00"}
                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Net Invoice Value Before Shipping(A)</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.fld_total_to_bepaid).toFixed(2)}
                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Shipping(B) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.Shipping_Charg == null ? 0.00 : data.Shipping_Charg).toFixed(2)}

                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  GST on Shipping(C) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.Shipping_Charg_gst == null ? 0.00 : data.Shipping_Charg_gst).toFixed(2)}

                                </td>

                              </tr>
                              <tr style={{ width: '100%', display: 'table' }}>
                                <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                  Total Billed Value(A+B+C) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                  {parseFloat(data.fld_total_to_bepaid + data.Shipping_Charg + data.Shipping_Charg_gst).toFixed(2)}

                                </td>

                              </tr>

                            </td>
                          );
                        }
                      })}
                    </tr>

                    <tr>
                      <td
                        colspan="5"
                        style={{
                          textAlign: "left",
                          paddingLeft: "1%",
                          paddingTop: "1%",
                          paddingBottom: "1%",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Amount in Words:
                        </span>{" "}
                        {this.state.AmountInWords} Only
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="5"
                        style={{
                          textAlign: "left",
                          paddingLeft: "1%",
                          paddingTop: "1%",
                          paddingBottom: "1%",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Payment Mode:
                        </span>{" "}
                        {this.state.Payment_Mode}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                        <span style={{ fontWeight: 'bold' }}>Payment By: </span> {" "}{this.state.PaymentBy}</td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "1000px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRightColor: "#000",
                    borderTop: "hidden",
                    fontFamily: "Lato, sans-serif",
                    borderCollapse: "collapse",
                    marginBottom: "30px",
                  }}
                  border="1"
                  cellspacing="1"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td
                        colspan="10"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                          background: "#f7f7f7",
                        }}
                      >
                        Have a Question?
                        <br /> Call us on
                        <a href={`tel:${this.state.clientPhoneNumber}`}>{this.state.clientPhoneNumber}</a> or
                        Email us at {" "}
                        <a href={`mailto:${this.state.clientEmail}`}>
                          {this.state.clientEmail}
                        </a>
                      </td>
                    </tr>

                    <tr class="success" style={{ backgroundColor: "#f7f7f7" }}>
                      <td
                        colspan="10"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                          textAlign: "center",
                          background: "#f7f7f7",
                        }}
                      >
                        Visit us at &nbsp;{" "}
                        <a href={this.state.clientWesite}>
                          {this.state.clientWesite}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInvoiceForm;
